<template>
    <div>
        <v-dialog
            v-model="modal"
            :dense="dense"
            light
            scrollable
            :fullscreen="fullscreen"
            :width="width">
            <template v-slot:activator="{ on, attrs }">
                <slot name="activator" v-bind:on="on" v-bind:attrs="attrs" v-bind:openDialog="pullHtml">
                    <v-btn
                        v-if="!hide"
                        :icon="icon != null && text == null"
                        :block="block"
                        :small="small"
                        v-bind="attrs" 
                        v-on="on"
                        @click.stop="pullHtml"
                        :class="buttonClass"
                        :text="text != null && !isButton"
                        :title="title">
                        <v-icon v-if="icon != null" :left="text != null">{{ icon }}</v-icon>
                        {{ text }}
                    </v-btn>
                </slot>
            </template>
            <v-card
                v-if="modal"
                :dense="dense"
                light>
                <v-card-text>
                    <v-toolbar v-if="showSettings" dense floating>
                        <v-menu
                            offset-y
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Settings"
                                    small>
                                    <v-icon small>mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <slot name="settings" v-bind:data="data" v-bind:settings="settings" :strategy="mStrat" />
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                    <div :id="elementID" style="width: 760px;">
                        <div v-if="rawHtml != null" v-html="rawHtml" />
                        <slot v-else v-bind:item="data" v-bind:settings="settings" :strategy="mStrat" />
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="primary" @click="modal=false">{{ cancelText }}</v-btn>
                    <v-spacer />
                    <v-btn text color="primary" @click="() => generatePDF(data)">{{ okText }}</v-btn>
                    <v-menu dense>
                        <template #activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs"
                                v-on="on"
                                icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="customStrategy = 'jspdf'">
                                <v-list-item-avatar>
                                    <v-icon v-if="mStrat == 'jspdf'" class="success--text">mdi-check</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Use JSPDF</v-list-item-title>
                                    <v-list-item-subtitle>Clearer but no images</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="customStrategy = 'html2pdf'">
                                <v-list-item-avatar>
                                    <v-icon v-if="mStrat == 'html2pdf'" class="success--text">mdi-check</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Use Html2PDF</v-list-item-title>
                                    <v-list-item-subtitle>Show images but not as clear</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-actions>
                <v-overlay :value="mLoadingMsg != null" class="text-center">
                    <v-progress-circular indeterminate size="64" />
                    <p>{{ mLoadingMsg }}</p>
                </v-overlay>
                <BT-Snack v-model="msg" />
            </v-card>
        </v-dialog>
        <v-overlay :value="modal == false && mLoadingMsg != null" class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>{{ mLoadingMsg }}</p>
        </v-overlay>
    </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';

export default {
    name: 'BT-Print', 
    data: function() {
        return {
            customStrategy: null,
            data: null,
            elementID: null,
            rawHtml: null,
            mLoadingMsg: null,
            modal: false,
            msg: null,
            settings: null
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        defaultStrategy: {
            type: String,
            default: 'jspdf' //or jspdf (html2pdf shows images but is more blurry. jspdf doesn't load images but is clearer.)
        },
        dense: {
            type: Boolean,
            default: true
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        getFileName: {
            type: Function,
            default: null
        },
        getSettings: {
            type: Function,
            default: null
        },
        hide: {
            type: Boolean,
            default: false
        },
        html: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: 'mdi-printer'
        },
        isButton: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        landscape: {
            type: Boolean,
            default: false
        },
        loadingMsg: {
            type: String,
            default: null
        },
        navigation: null,
        okText: {
            type: String,
            default: 'Generate PDF'
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Print'
        },
        title: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: '/get/HTML/'
        },
        value: null,
        width: {
            type: String,
            default: '900'
        },
    },
    computed: {
        mStrat() {
            return this.customStrategy ?? this.defaultStrategy ?? 'html2pdf';
        }
    },
    mounted() {
        this.rawHtml = this.value;
        
        if (this.rawHtml != null) {
            this.modal = true;
        }
        
        if (this.getSettings != null) {
            this.settings = this.getSettings();
        }

        this.elementID = this.getUniqueID();

        this.mLoadingMsg = this.loadingMsg;
    },
    watch: {
        loadingMsg(val) {
            this.mLoadingMsg = val;
        },
        showToggle() {
            this.modal = true;
            this.pullHtml();
        }
    },
    methods: {
        async generatePDF(data) {
            var strat = this.mStrat;

            var fileName = 'bt-printable.pdf';
            try {
                fileName = this.getFileName != null ? this.getFileName(data) : 'bt-printable.pdf';
            }
            catch {
                console.log('could create file name');
                console.log(data);
            }

            if (strat == 'html2pdf') {
                var element = document.getElementById(this.elementID);
                console.log(window.devicePixelRatio);
                html2pdf()
                    .set({
                        filename: fileName,
                        html2canvas: {
                            scale: 5,
                            useCORS: true
                        },
                        jsPDF: {
                            orientation: this.landscape ? 'l' : 'p',
                            unit: 'px',
                            format: 'a4',
                            hotfixes: ['px_scaling']
                        }
                    })
                    .from(element)
                    .save(fileName);
            }
            else if (strat == 'jspdf') {
                var doc = new jsPDF({
                    orientation: this.landscape ? 'l' : 'p',
                    unit: 'px',
                    format: 'a4',
                    hotfixes: ['px_scaling']
                });

                doc.html(document.getElementById(this.elementID), {
                    callback: function(doc) {
                        window.open(doc.output('bloburl'), '_blank');
                    },
                    x: 15,
                    y: 25
                });
            }
            
            this.modal = false;
        },
        async pullHtml() {
            if (this.navigation != null && this.rawHtml == null && this.url != null && this.itemID != null) {
                try {
                    this.mLoadingMsg = 'Pulling HTML';
                    this.$forceUpdate();
                    var res = await this.$BlitzIt.api.get(this.navigation, this.url + this.itemID, null, null)
                    this.rawHtml = res.data.data;
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.mLoadingMsg = null;
                }
            }
            else if (this.onPullSuccessAsync != null) {
                try {
                    this.mLoadingMsg = 'Pulling Data';
                    this.$forceUpdate();
                    this.data = await this.onPullSuccessAsync(this.item, this.itemID);
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.mLoadingMsg = null;
                }
            }
            else {
                this.data = this.item; // { item: this.item, settings: this.settings };
            }

            this.modal = true;
        },
        // async share() {
        //     var doc = new jsPDF({
        //         orientation: 'p',
        //         unit: 'px',
        //         format: 'a4',
        //         hotfixes: ['px_scaling']
        //     });

        //     doc.html(document.getElementById(this.elementID), {
        //         callback: async function(doc) {
        //             var u = doc.output('bloburl');
        //             console.log('uri');
        //             u = u.replace('blob:', '');
        //             console.log(u);

        //             var shareData = {
        //                 title: 'Test',
        //                 text: 'Just a test share object',
        //                 url: u + '.jpg'
        //             }

        //             try {
        //                 await navigator.share(shareData);
        //             }
        //             catch (err) {
        //                 console.log(err);
        //             }

        //             // window.open(u, '_blank');
        //         },
        //         x: 15,
        //         y: 25
        //     });
            
        //     this.modal = false;
        // }
    }
}
</script>