var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"dense":_vm.dense,"light":"","scrollable":"","fullscreen":_vm.fullscreen,"width":_vm.width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [(!_vm.hide)?_c('v-btn',_vm._g(_vm._b({class:_vm.buttonClass,attrs:{"icon":_vm.icon != null && _vm.text == null,"block":_vm.block,"small":_vm.small,"text":_vm.text != null && !_vm.isButton,"title":_vm.title},on:{"click":function($event){$event.stopPropagation();return _vm.pullHtml.apply(null, arguments)}}},'v-btn',attrs,false),on),[(_vm.icon != null)?_c('v-icon',{attrs:{"left":_vm.text != null}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" ")],1):_vm._e()]},{"on":on,"attrs":attrs,"openDialog":_vm.pullHtml})]}}],null,true),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('v-card',{attrs:{"dense":_vm.dense,"light":""}},[_c('v-card-text',[(_vm.showSettings)?_c('v-toolbar',{attrs:{"dense":"","floating":""}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":"Settings","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog")])],1)]}}],null,false,1186248615)},[_c('v-list',{attrs:{"dense":""}},[_vm._t("settings",null,{"data":_vm.data,"settings":_vm.settings,"strategy":_vm.mStrat})],2)],1)],1):_vm._e(),_c('div',{staticStyle:{"width":"760px"},attrs:{"id":_vm.elementID}},[(_vm.rawHtml != null)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.rawHtml)}}):_vm._t("default",null,{"item":_vm.data,"settings":_vm.settings,"strategy":_vm.mStrat})],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal=false}}},[_vm._v(_vm._s(_vm.cancelText))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () { return _vm.generatePDF(_vm.data); }}},[_vm._v(_vm._s(_vm.okText))]),_c('v-menu',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.customStrategy = 'jspdf'}}},[_c('v-list-item-avatar',[(_vm.mStrat == 'jspdf')?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Use JSPDF")]),_c('v-list-item-subtitle',[_vm._v("Clearer but no images")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.customStrategy = 'html2pdf'}}},[_c('v-list-item-avatar',[(_vm.mStrat == 'html2pdf')?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Use Html2PDF")]),_c('v-list-item-subtitle',[_vm._v("Show images but not as clear")])],1)],1)],1)],1)],1),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.mLoadingMsg != null}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}),_c('p',[_vm._v(_vm._s(_vm.mLoadingMsg))])],1),_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})],1):_vm._e()],1),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.modal == false && _vm.mLoadingMsg != null}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}),_c('p',[_vm._v(_vm._s(_vm.mLoadingMsg))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }